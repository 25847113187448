var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"login_page login_main"},[_c('div',{staticClass:"page_container"},[_c('div',{staticClass:"login_inner"},[_c('div',{staticClass:"login_block"},[_c('div',{staticClass:"login_top"},[_c('Logo'),_c('LngSwitcher',{attrs:{"lngBlock":"login_lg"}})],1),_c('div',{staticClass:"login_form"},[_c('div',{staticClass:"form_title"},[_vm._v(" "+_vm._s(_vm.$t("Log in"))+" ")]),_c('ValidationObserver',{ref:"log_validation",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('ValidationProvider',{attrs:{"name":"Contract","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field_block",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"field_title"},[_vm._v(" "+_vm._s(_vm.$t("Contract number"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contract_number),expression:"contract_number"}],attrs:{"type":"text","name":"number","autocomplete":"off","new-password":"off","placeholder":"XX XX XXX"},domProps:{"value":(_vm.contract_number)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.contract_number=$event.target.value},_vm.updateValue]}}),_c('span',{staticClass:"error_hint"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"icon_info check_input"})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field_block",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"field_title"},[_vm._v(" "+_vm._s(_vm.$t("Password"))+" ")]),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"pass_input",attrs:{"name":"password","autocomplete":"off","placeholder":"Ձեր գաղտնաբառը","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"pass_input",attrs:{"name":"password","autocomplete":"off","placeholder":"Ձեր գաղտնաբառը","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"pass_input",attrs:{"name":"password","autocomplete":"off","placeholder":"Ձեր գաղտնաբառը","type":_vm.type},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"error_hint"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"icon_show show_pass",class:{hide_pass: _vm.type === 'text'},on:{"click":function($event){_vm.type === 'password'
                          ? (_vm.type = 'text')
                          : (_vm.type = 'password')}}})])]}}],null,true)}),_c('div',{staticClass:"forget_pass"},[_c('router-link',{attrs:{"to":"/forgot-password","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var navigate = ref.navigate;
                          var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t("Forgot password")))])]}}],null,true)})],1),_c('ul',{staticClass:"checkbox_list"},[_c('li',[_c('label',[_c('span',{staticClass:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.remember_me),expression:"remember_me"}],attrs:{"type":"checkbox","name":"checkboxbutton"},domProps:{"checked":Array.isArray(_vm.remember_me)?_vm._i(_vm.remember_me,null)>-1:(_vm.remember_me)},on:{"change":function($event){var $$a=_vm.remember_me,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.remember_me=$$a.concat([$$v]))}else{$$i>-1&&(_vm.remember_me=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.remember_me=$$c}}}}),_c('span',{staticClass:"checkbox_button icon_checked"},[_vm._v(_vm._s(_vm.$t("Remember me")))])])])])]),_c('button',{staticClass:"validate_btn"},[_vm._v(" "+_vm._s(_vm.$t("Log in"))+" ")])],1)]}}])}),_c('div',{staticClass:"bottom_block"},[_c('router-link',{staticClass:"enter_btn",attrs:{"custom":"","to":"/signup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var navigate = ref.navigate;
                          var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t("Register")))])]}}])})],1)],1)]),_c('AppStore')],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }